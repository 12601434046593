import React from 'react';
import { Helmet } from 'react-helmet';

import { ReactSVG } from 'react-svg';
import CategoryNav from "./CategoryNav";
import VideoGrid from "../Videos/VideoGrid";
import tw from 'twin.macro';
import { HomeStyles } from './Home.styled';
import { instanceOf } from 'prop-types';
import { css } from 'styled-components/macro';

import ChevronLeft from 'pubweb-smokey/dist/images/svg/iconography-16x16/chevron-lft.svg';

const Home = (props) => {

    const currentCat = props.info.allCategories[props.info.activeCategory];

    return (
        <>

            <Helmet>
                <title>Expert Home Care Tips and Repair Ideas from Clayton</title>
                <meta name="description" content="Get step-by-step home maintenance tutorials to help keep your home in good repair and looking its best." />
                <meta property="og:image" content="https://embed-fastly.wistia.com/deliveries/ed12956e8a5dfef30f0064f64e62b6a2.webp" />
                <meta property="og:title" content="Expert Home Care Tips and Repair Ideas from Clayton" />
                <meta property="og:description" content="Get step-by-step home maintenance tutorials to help keep your home in good repair and looking its best." />
            </Helmet>

            <HomeStyles>
                <div className="sidebar">
                    <CategoryNav {...props.info} />
                </div>
                <div className="main-content">

                    {/* Meta Heading */}
                    <div className="main-meta">
                        <div className="breadcrumbs">
                            {(props.info.activeCategory > 0 || props.info.activeCategory == -1)  &&
                                <a 
                                    id="previous-category" 
                                    name="Previous Category" 
                                    alt="Previous Category" 
                                    className="back" 
                                    onClick={() => props.info.getCategory('all', 0)}
                                >
                                    <ReactSVG className="icon" src={ChevronLeft} />
                                </a>
                            }
                            <span className="label large blue">{ props.info.activeCategory >= 0 ? currentCat.label : "Search"}</span>
                        </div>
                        <div>
                            {/* <span className="label large">Sort By: </span><span className="label large blue">Most Recent</span> */}
                        </div>
                    </div>

                    {/* Category Heading */}
                    {props.info.activeCategory >= 0 &&
                        <div tw="text-center mx-auto px-4 max-w-screen-md mt-4 sm:mt-8">
                            <h2 tw="max-w-xs px-6 mx-auto sm:max-w-none sm:px-0">{currentCat.heading}</h2>
                            <p tw="mt-4 px-4 sm:px-0">{currentCat.text}</p>
                        </div>
                    }
                    
                    {/* Search Heading */}
                    {props.info.activeCategory < 0 &&
                        <div tw="text-center mx-auto px-4 max-w-screen-md my-8">
                            <h2>Searching "{props.info.searchTerm}"</h2>
                            <p tw="mt-4">Found {props.info.videoData.length} results.</p>
                        </div>
                    }

                    {/* Video Results */}
                    <VideoGrid videos={props.info.videoData} />
                </div>
            </HomeStyles>

        </>
    )
};

export default Home;
