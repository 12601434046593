import React, { useRef, useState } from 'react';
import { ReactSVG } from 'react-svg'
import VideoCardStyles from './VideoCard.styled';
import { disableScroll, enableScroll } from '../../utils/config';

import Button from 'pubweb-smokey/dist/components/Buttons';
import ChevronDown from 'pubweb-smokey/dist/images/svg/iconography-16x16/chevron-dwn.svg';
import ChevronUp from 'pubweb-smokey/dist/images/svg/iconography-16x16/chevron-up.svg';

function VideoCard({ title, description, thumbnailID, wistiaID, url, slug }) {

    const [descriptionShown, setDescriptionShown] = useState(false);
    const wistiaTarget = useRef();
    var wistiaWrap;
    var wistiaVideo;

    const wrapTarget = () => {
        window._wq = window._wq || [];
        window._wq.push({id: wistiaID, onReady: function(video) {
            wistiaVideo = video;

            // immediately show and play popover when ready
            video.popover.show();
            video.play();

            // freeze scrolling under player
            disableScroll();

            // always clean up popover when hiding popover
            video.bind("popoverhide", function() {
                unwrapTarget();
                enableScroll();
            });
        }});

        if (!document.getElementById('wistia-'+wistiaID)) {
            const script1 =  document.createElement("script");
            script1.id = `wistia-` + wistiaID;
            script1.type="text/javascript";
            script1.src = `https://fast.wistia.com/embed/medias/` + wistiaID + `.jsonp`;
            script1.async = true;
            document.body.appendChild(script1);
        }

        if (wistiaTarget.current.parentNode) {
            wistiaWrap = document.createElement('div');
            wistiaWrap.className = "wistia-wrap wistia_embed wistia_async_"+wistiaID+" popover=true popoverContent=link preload=none stillUrl=data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII= popoverCaption="+encodeURIComponent(title);

            wistiaTarget.current.parentNode.insertBefore(wistiaWrap, wistiaTarget.current);
            wistiaWrap.appendChild(wistiaTarget.current);
        }
    };

    const unwrapTarget = () => {
        // remove wistia wrap from Dom
        if (wistiaWrap.parentNode !== null) {
            wistiaWrap.parentNode.insertBefore(wistiaTarget.current, wistiaWrap);
            wistiaWrap.remove();
        }

        // perform Wistia's own cleanup for scripts
        if (wistiaVideo) {
            wistiaVideo.remove();
        }

        // remove embed assets if found            
        if (document.contains(document.getElementById('wistia-'+wistiaID))) {
            document.getElementById('wistia-'+wistiaID).remove();
        }
        if (document.contains(document.getElementById('wistia-'+wistiaID+'-1_popover_container'))) {
            document.getElementById('wistia-'+wistiaID+'-1_popover_container').remove();
        }
        if (document.contains(document.getElementById('wistia-'+wistiaID+'-1_popover'))) {
            document.getElementById('wistia-'+wistiaID+'-1_popover').remove();
        }
    };

    const getVideo = (event) => {
        event.preventDefault();
        wrapTarget();
    };

    return (
        <VideoCardStyles className={descriptionShown ? "show-description" : "hide-description"}>

            {/* Wistia Popover Hidden Target */}
            <div ref={wistiaTarget} tw="opacity-0 absolute z-sub10"></div>

            {/* Thumbnail with link */}
            <a 
                href={url} 
                onClick={(event) => getVideo(event)} 
                className={`${slug ? "gtm_" + slug + "_image" : ""}`} 
                aria-label={`Watch the ${title} video.`}
            >
                <img 
                className="thumbnail" 
                src={`https://embed-fastly.wistia.com/deliveries/${thumbnailID}.webp?image_resize=600&image_play_button=true&image_play_button_color=0075C9`} 
                width="600"
                height="338"
                alt={`${title} video thumbnail`} />
            </a>

            <h4 className="title">{title}</h4>
            <p className="description">{description}</p>
            <div className="card-meta">
                <a 
                    alt="Show more information."
                    className={`label large${slug ? " gtm_" + slug + "_show_more" : ""}`} 
                    tw="cursor-pointer text-ch-standard-black" 
                    onClick={() => setDescriptionShown(!descriptionShown)}
                >
                    {descriptionShown ? "Show Less" : "Show More"} 
                    <ReactSVG className="icon" alt="Down arrow" src={descriptionShown ? ChevronUp : ChevronDown} />
                </a>
                <Button 
                    automationId={`learn-more`}
                    className={`learn-more${slug ? " gtm_" + slug + "_cta" : ""}`}
                    text="Learn More" 
                    isCompact="true"
                    navigateUrl={url}
                    target="_blank"
                    rel="noopener"
                    alt={`Learn more about ${title}`}
                />
            </div>
        </VideoCardStyles>
    )
}

export default VideoCard
