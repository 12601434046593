import React, { useRef, useEffect } from 'react';
import { HeaderStyles } from './Header.styled';
import CategoryNav from "../../Home/CategoryNav";
import { pushGTMEvent } from 'pubweb-smokey/dist/utils/analytics';
import { disableScroll, enableScroll, getClaytonHomesDomainUrl } from '../../../utils/config';
import tw from 'twin.macro'
import { css } from 'styled-components/macro';
import MenuClose from 'pubweb-smokey/dist/images/svg/iconography-16x16/x.svg';
import MenuBurger from 'pubweb-smokey/dist/images/svg/iconography-16x16/hamburger.svg';
import MenuSearch from 'pubweb-smokey/dist/images/svg/iconography-16x16/magnifying-glass.svg';
import Logo from '../../../images/HomeCare_Logo.svg';

if (typeof (window) !== "undefined" && typeof (document) !== "undefined" && !document.getElementById("ccpa_script")) {
  let source = `https://privacy.claytonhomes.com/ccpa/v5/index.min.js`;
  let script = document.createElement('script');
  script.src = source;
  script.defer = true;
  script.id = "ccpa_script";
  script.setAttribute("data-id", "ccpa-banner");
  script.setAttribute("data-first-link-text", "Privacy Policy");
  script.setAttribute("data-first-link-href", `${getClaytonHomesDomainUrl()}/privacy/`);
  document.getElementsByTagName("head")[0].appendChild(script);
}

const Header = (props) => {

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      props.info.searchVideos(props.searchRef.current.value);

      // Tack GTM event
      pushGTMEvent('ga_video_search');
    }
  }

  const handleSearchIconClick = (event) => {
    // Toggle search menu visibility
    props.info.setSearchOpen(!props.info.searchOpen);

    // focus the text field
    props.searchRef.current.focus();
  };

  const handleMenuButtonClick = (event) => {

    // Toggle NavOpen
    props.info.setNavOpen(!props.info.navOpen)

    // Updated method for isMobile check
    // Note: this can cause issues if the
    // user changes to a larger width, like
    // by rotating view to landscape]
    const max = 768;
    if (screen.width < max || window.innerWidth < max) {
      if (!props.info.navOpen) {
        disableScroll();
      } else {
        enableScroll();
      }
    }
  };

  return (
    <HeaderStyles>
      <div className={`nav-container ${props.info.searchOpen ? "search-open" : "search-closed"}`}>
        <div className="nav-primary">
          <div className="logo" data-test-id="logo-click">
            <img src={Logo} width="150" height="40" alt="HBG Home Care Guide Logo" />
          </div>
          <ul>
            <li>
              <a href="/" alt="Home Care Guide Videos" tw="font-sans">Videos</a>
            </li>
          </ul>
        </div>
        <div className="nav-search">
          <div className="search-form">
            <label>
              <input 
                id="global-search"
                name="search"
                ref={props.searchRef}
                placeholder="Search for something"
                onKeyDown={handleKeyDown} 
                />
            </label>
            <span className="search-error"></span>
          </div>
          <button
            alt="Search Button"
            data-test-id="menu-search"
            className="menu-search" onClick={() => handleSearchIconClick()}>
            <img src={MenuSearch} alt="Search Button Image" />
          </button>
        </div>
        <div className={`nav-mobile ${props.info.navOpen ? "nav-open" : "nav-closed"}`}>
          <button
            alt="Navigation Toggle Button"
            data-test-id="menu-toggle"
            className="nav-burger"
            onClick={() => handleMenuButtonClick()}>
            <img src={props.info.navOpen ? MenuClose : MenuBurger} alt="Navigation Button Image" />
          </button>
          <div className="nav-mobile-drawer">
            <CategoryNav {...props.info} />
          </div>
        </div>
      </div>


    </HeaderStyles>
  )
}

export default Header;
