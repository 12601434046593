import { createGlobalStyle } from "styled-components";
import * as variables from "pubweb-smokey/dist/components/GridSystem/_vars_widths.js";

const GlobalStyle = createGlobalStyle`
/* put styles that are sitewide here.
they should be few and far between. */
* {
    box-sizing: border-box;
    font-family: 'acumin-pro', Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
}

.constrained-width {
    margin: 0 auto;
    width: ${variables.mobile_width_percentage}%;
}

@media only screen and (min-width: ${variables.tablet_breakpoint}px){
   .constrained-width {
        max-width: ${variables.tablet_container_maxwidth}px; 
   }
}

@media only screen and (min-width: ${variables.desktop_breakpoint}px){
   .constrained-width {
        max-width: ${variables.desktop_container_maxwidth}px; 
   }
}

/* Osano styles (e.g. hide cookie widget) */
.osano-cm-widget {
    display: none;
}
.osano-cm-dialog {
    display: none;
}
.osano-cm-info * {
    font-family: Helvetica, Arial, sans-serif;  
}

`;

export default GlobalStyle;
