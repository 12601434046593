import styled from 'styled-components';
import tw from 'twin.macro';
import { up, down } from 'styled-breakpoints';

import zIndices, { setBelow, setSameAs } from 'pubweb-smokey/dist/components/zIndex'

let zIndicies = {
  entireComponent: setBelow(zIndices.dropDownMenuModal), // sets the z-index to below what dropDownMenuModal's
  navMenuItemsDesktop: setSameAs(), // sets the z-index to be the same as previous one
  navLogoBurgerMobile: setSameAs(), // sets the z-index to be the same as previous one
  navMenuItemsMobile: setBelow(), // sets the z-index to be below the previous one
};

export const HeaderStyles = styled.div`
  ${tw`bg-ch-standard-white w-full sticky top-0`};
  z-index: ${zIndicies.entireComponent};

  a:link,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
  }

  .menu-search img {
    ${tw`w-8 h-8`};
  }

  .nav-burger img {
    ${tw`w-10 h-10`};
  }

  // Wrap Navigation and Mobile Menu
  .nav-container {
    ${tw`flex h-16 items-center border-b border-ch-grey-4`};
  }

  // Logo and default navigation links
  .nav-primary {
    ${tw`h-full flex items-center flex-1 px-4 justify-between`};

    // Hide nav links on mobile
    // Note: this layout mixes nav and categories,
    // so the nav is duplicated for mobile
    ul {
      ${tw`hidden md:inline`};
    }
  }


  .nav-search {
    ${tw`h-full flex items-center px-4 bg-ch-grey-6`};

    ${up('md')} {
      ${tw`border-l border-ch-grey-4`};
    }
  }

  .search-form {
    ${tw`relative md:inline`};

    input {
      ${tw`bg-transparent font-normal font-sans border-b border-ch-standard-black mr-4 focus:outline-none`};
    }

    .search-error {
      ${tw`block font-sans font-normal text-xs absolute text-ch-barn-door`};
    }
  }

  // Search open/close states
  // only on mobile
  ${down('sm')} {
    .nav-search {
      ${tw`bg-ch-standard-white`};
    }

    .search-form {
      ${tw`opacity-0 z-sub10 absolute right-0`};
    }

    .search-open {
      .nav-primary {
        ${tw`hidden`};
      }

      .nav-search {
        ${tw`flex-1 bg-ch-grey-6`};
      }
      .search-form {
        ${tw`flex-1 pr-4 opacity-100 z-0 relative`};

        input {
          ${tw`w-full`};
        }
      }
    }
  }

  // Mobile Nav Button Group
  .nav-mobile {
    ${tw`h-full flex items-center px-4 border-l border-ch-grey-4 md:hidden`};
  }

  .nav-mobile-drawer {
    ${tw`fixed top-16 bottom-0 left-0 inset-x-0 bg-ch-grey-6 text-center`};
  }

  .nav-closed .nav-mobile-drawer {
    ${tw`hidden`};
  }
  
`;