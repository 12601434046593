import React from 'react';
import CategoryNavStyles from "./CategoryNav.styled";

export default function CategoryNav(props) {

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.target.children[0].click();
        }
    }

    return (
        <CategoryNavStyles>
            <h3 className="heading">Videos</h3>
            <ul className="nav">
                {props.allCategories.map((item, i) => (
                    <li key={i} onKeyDown={handleKeyDown} tabIndex={i+1}>
                        <a 
                            alt={`${item.label} Category`}
                            className={`nav-link${props.activeCategory == i ? " active" : ""} nav-${item.slug} gtm_nav_${item.slug}`}
                            onClick={() => props.getCategory(item.slug, i)}
                        >
                            {item.label}
                        </a>
                    </li>
                ))}
            </ul>
        </CategoryNavStyles>
    )
}
