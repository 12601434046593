import App from './App';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrate } from 'react-dom';
import DXAccountContext from 'clayton-chcom/dist/contexts/DXAccountContext';
import { ApolloProvider } from '@apollo/client';
import { AuthorizationProvider } from 'dx-sdk/build/authorization';
import {
  getAuth0ClientId,
  getAuth0Domain,
  getAuth0Audience,
  getAuth0Cache,
  getAuth0Scope,
} from 'clayton-chcom/dist/utils/config';
import { apolloClient } from 'clayton-chcom/dist/utils/utils';

hydrate(
  <BrowserRouter>
    <AuthorizationProvider
      clientId={getAuth0ClientId}
      domain={getAuth0Domain}
      audience={getAuth0Audience}
      useRefreshTokens
      cacheLocation={getAuth0Cache}
      scope={getAuth0Scope}
    >
      <ApolloProvider client={apolloClient}>
        <DXAccountContext>
          <App />
        </DXAccountContext>
      </ApolloProvider>
    </AuthorizationProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}
