import styled from 'styled-components';
import tw from 'twin.macro';

const CategoryNav = styled.div`
    ${tw`bg-ch-grey-6 border-b border-ch-grey-4 py-14 md:sticky md:top-16`};

    .heading {
        ${tw`px-16`};
    }

    .nav {
        ${tw`py-4`};
    }

    .nav-link {
        ${tw`block px-16 py-3 font-sans font-normal cursor-pointer border-t border-b border-transparent`};

        &.active {
            ${tw`bg-adjusted-blue text-ch-clayton-blue border-ch-dark-blue-tint`};
        }
    }

`;

export default CategoryNav;