export const ENVIRONMENT = '#{terraformEnvCode}';
export const ANALYTICS_ID = 'UA-138585709-6';
export const GTM_ID = 'GTM-T55KBWZ';

export const getSiteUrl = (relativeUrl = "") => {

    //This is to overstep a local issue where local develop uses the full path automatically.
    if (relativeUrl.indexOf("localhost") !== -1){
        return relativeUrl;
    }
    // TODO: put crossmod domain here (or path on ch.com)
    let domain = (ENVIRONMENT.indexOf('terraformEnvCode') !== -1) ? "localhost:3000" : `clayton-built.${ENVIRONMENT}.cct-pubweb.com`;
    if (ENVIRONMENT.indexOf('prod') !== -1){
        domain = "www.claytonhomecare.com";
    } 

    return `https://${domain}${relativeUrl}`;
};

export const getClaytonHomesDomainUrl = () => {
    let domain = (ENVIRONMENT.indexOf('terraformEnvCode') !== -1) ? "claytonhomes.dev.cct-pubweb.com" : `claytonhomes.${ENVIRONMENT}.cct-pubweb.com`;
    if (ENVIRONMENT.indexOf('prod') !== -1){
        domain = "www.claytonhomes.com";
    }

    return `https://${domain}`
}

export const disableScroll = () => {
    // console.log('scrolling disabled');
    document.body.style.overflow = "hidden";
}

export const enableScroll = () => {
    // console.log('scrolling enabled');
    document.body.style.overflow = "auto";
}