import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import NotFoundContent from 'clayton-chcom/dist/components/NotFound/NotFoundContent';

// Always set the http status code to 404.  This will set "res.statusCode" in Express.
const RouteWrapper = ({ children }) => (
    <Route
        render={({ staticContext }) => {
            if (staticContext) {
                staticContext.statusCode = 404;
            }
            return children;
        }}
    />
);

RouteWrapper.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

const NotFoundPage = () => {
    return (
        <RouteWrapper>
            <Fragment>
                <Helmet>
                    <title>Not Found | Expert Home Care Tips and Repair Ideas from Clayton</title>
                    <meta name="description" content="Get step-by-step home maintenance tutorials to help keep your home in good repair and looking its best." />
                    <meta property="og:image" content="https://embed-fastly.wistia.com/deliveries/ed12956e8a5dfef30f0064f64e62b6a2.webp" />
                </Helmet>
                <NotFoundContent addSiteUrl={true} internalLink={false} />
            </Fragment>
        </RouteWrapper>
    );
};

export default NotFoundPage;
