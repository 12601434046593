import styled from 'styled-components';
import tw from 'twin.macro';
import { up, down } from 'styled-breakpoints';

export const HomeStyles = styled.div`
    ${tw`flex`};

    .sidebar {
        ${tw`w-64 border-r border-ch-grey-4 bg-ch-grey-6`};

        ${down('sm')} {
            ${tw`hidden`};
        }
    }

    .main-content {
        ${tw`flex-1`};
    }

    .main-meta {
        ${tw`flex justify-between items-center h-12 w-full px-6`};

        span {
            ${tw`text-ch-grey-1`};
        }

        a {
            ${tw`text-ch-clayton-blue`};
        }

        .blue {
            ${tw`text-ch-clayton-blue`};
        }
    }

    .breadcrumbs {
        ${tw`flex items-center`};

        .icon {
            ${tw`mt-1.5`};
        }

        .back {
            ${tw`mr-2 cursor-pointer`};
        }

        svg {
            ${tw`text-ch-clayton-blue`};

            path {
                ${tw`fill-current`};
            }
        }
    }
`;