import React, { useEffect } from 'react'
import VideoGridStyles from './VideoGrid.styled';
import VideoCard from "./VideoCard";

export default function VideoGrid({ videos }) {

    useEffect(() => {
         // Taken from pubweb.smokey -> WistiaVideoEmbed
         if(typeof(document) !== 'undefined') {
            const script1 =  document.createElement("script");
            script1.id = "wistia-E-v1";
            script1.src = "https://fast.wistia.com/assets/external/E-v1.js";
            script1.async = true;
        
            if (!document.getElementById('wistia-E-v1'))
                document.body.appendChild(script1);
        }
    });

    return (
        <VideoGridStyles>
            {videos.map((item, index) => (
                <VideoCard {...item} key={index} />
            ))}
        </VideoGridStyles>
    )
}
