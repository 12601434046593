import styled from 'styled-components';
import tw from 'twin.macro';

const VideoCardStyles = styled.div`

    ${tw`border-b border-ch-grey-4 pb-4 mb-4`};

    .wistia-wrap {
        ${tw`absolute opacity-0 z-sub10`};
    }

    .wistia_click_to_play {
        width: 100% !important;
        height: auto !important;
    }

    .thumbnail {
        ${tw`w-full inline-block`};
    }

    .title {
        ${tw`border-b border-ch-grey-4 pb-2 mb-2 mt-4`};
    }

    .description {
        ${tw`pb-4 truncate`};
    }

    .icon {
        ${tw`inline-block align-middle ml-1 mb-0.5`};

        svg {
            ${tw`text-ch-clayton-blue`};

            path {
                ${tw`fill-current`};
            }
        }
    }

    &.show-description .description {
        ${tw`whitespace-normal`};
    }

    .card-meta {
        ${tw`flex justify-between items-center py-1`};
    }

    /* .learn-more {
        ${tw`py-1 px-3`};
    } */
`;

export default VideoCardStyles;