import React, { useRef, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import Fuse from 'fuse.js';
import GlobalStyle from './base.js';
import 'tailwindcss/dist/base.min.css';
import Home from './components/Home/Home';
import NotFoundPage from './components/NotFound/NotFoundPage';
import { ThemeProvider } from 'styled-components';
import defaultConfig from 'tailwindcss/defaultConfig';
import Header from './components/Shared/Header/Header';
import Footer from 'clayton-chcom/dist/components/Shared/Footer/Footer';
import Typography from "pubweb-smokey/dist/components/Typography/Typography";
import { enableScroll, getClaytonHomesDomainUrl } from './utils/config';

// video data
import data from "./components/Videos/data.json";

const App = () => {

  // Add tailwind breakpoints to Theme Provider
  // in order to use breakpoints in styled components
  // through ${up('md')} or ${down('lg')}, etc.
  const theme = {};
  theme.breakpoints = defaultConfig.theme.screens;

  /*
   * Not sure if this is correct, but added
   * states and functions to global app so that
   * child pages could access search and category data
   * from the shared Header.js component...
   */

  // Global states for App
  const [navOpen, setNavOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [videoData, setVideoData] = useState(data.videos);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeCategory, setActiveCategory] = useState(0);

  // store global search field ref
  // to pass to header component
  const searchRef = useRef();

  // Fuse search for categories only
  const CatSearch = new Fuse(data.videos, {
    threshold: 0.0,
    keys: ["categories"]
  });

  // Fuse search for all data
  const CustomSearch = new Fuse(data.videos, {
    threshold: 0.0,
    keys: ["title", "description", "categories", "keywords"]
  });

  // Sort by Date Descending
  const stortDateDesc = (a, b) => {
    var test = a.date < b.date;
    return test ? 1 : -1;
  };

  // Sort by date Ascending
  const stortDateAsc = (a, b) => {
    var test = a.date > b.date;
    return test ? 1 : -1;
  };

  // Get a category based on key and index
  const getCategory = (cat, index) => {
    //console.log('switching to category => ' + cat + ' ['+index+']');

    // Get videos
    var filtered = CatSearch.search(cat).map((item) => item.item);

    // set states
    setVideoData(filtered);
    setActiveCategory(index);

    // reset search field value
    searchRef.current.value = "";

    // make sure mobile nav is closed
    setNavOpen(false);
    enableScroll();


    // scroll to top of page
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  // Search a custom term
  const searchVideos = (term) => {
    //console.log('searching for ' + searchRef.current.value);

    if (term == ""){
      getCategory('all', 0);
      return;
    }

    // Get videos
    var filtered = CustomSearch.search(term).map((item) => item.item);

    // set states
    setVideoData(filtered);
    setSearchTerm(term);
    setActiveCategory(-1);

    // make sure mobile nav is closed
    setNavOpen(false);
    enableScroll();

    // scroll to top of page
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  // Package to send to children
  // Both Header and Page need to be
  // aware of category and search states
  var appInfo = {
    getCategory,
    videoData,
    activeCategory,
    allCategories: data.categories,
    navOpen,
    setNavOpen,
    searchOpen,
    setSearchOpen,
    searchVideos,
    searchTerm,
  };

  return (
    <ThemeProvider theme={theme}>
      <Typography>
        <div>
          <GlobalStyle />

          <Header info={appInfo} searchRef={searchRef} />
          <div className="site-container">
            <Switch>
              <Route exact path="/" render={() => (
                <Home info={appInfo} />
              )} />
              <Route component={NotFoundPage} />
            </Switch>
          </div>
          <Footer 
            internalLink={false}
            siteUrlOverride={getClaytonHomesDomainUrl()}
            showTwitter={false}
            ccpaOverride=''
            ssr={false}
          />
        </div>
      </Typography>
    </ThemeProvider>
  )
};

export default App;
